.ReactModalPortal {
  z-index: 2000 !important;
}

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 100ms ease-in-out;
  background: rgba(0, 0, 0, 0.15);
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}
