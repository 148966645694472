// Variables
// Copied from hiboo-style/src/hiboo/_variables.scss
// Only required variables copied

$blue: #4164fb;
$dark-blue: #4b5c7c;
$gray-300: #dee2e6 !default;
$gray-900: #212529 !default;
$green: #2ab27b;
$light-grey: #f6f9fc;
$medium-grey: #b2b2b2;
$strong-blue: $dark-blue;

$body-color: $strong-blue !default;
$border-radius: 0.25rem !default;

$list-group-active-border-color: $blue !default;
$list-group-active-border-size: 0.25rem !default;
$list-group-border-color: #e6ebf1 !default;
$list-inline-padding: 0.5rem !default;

$spacer: 1rem !default;
