[data-rsbs-header] {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px -16px 32px -14px !important;
  margin-bottom: -1px !important;
  z-index: 0;
}

[data-rsbs-header]:before {
  position: unset;
  width: 42px;
  height: 4px;
  background: #d5d8db; /* gray/300 */
  border-radius: 20px;
  transform: unset;
}

[data-rsbs-overlay] {
  z-index: 1005;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: transparent;
  box-shadow: unset !important;
}

[data-rsbs-scroll] {
  flex-grow: 0;
  background: white;
  z-index: 0;
}
