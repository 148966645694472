.link {
  display: inline-block;
  color: #000;
  text-decoration: none;
  padding: 0px 7px;
  height: 32px;
  line-height: 32px;
  &:hover {
    color: #000;
    text-decoration: none;
    background-color: #f6f9fc;
  }
  &:active {
    background-color: #d5d8db;
  }
  &.fullWidth {
    display: block;
    width: 100%;
  }
}
