@keyframes resizeAnimation {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

.DateRangePicker {
  background: white;
  box-shadow: 0px 2px 5px 0px #dbdbde;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  border: 1px solid rgba(15, 15, 15, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.DateRangePicker_picker {
  z-index: 1000;
  flex: 0.6;
  border: 1px solid #d5d8db;
  box-shadow: 0 9px 24px rgba(15, 15, 15, 0.2), 0 3px 6px rgba(15, 15, 15, 0.1),
    0 0 0 rgba(15, 15, 15, 0.05);
  border-radius: 5px;
  animation-name: resizeAnimation;
  animation-duration: 200ms;
  animation-timing-function: ease-in;
}

.DateInput_input {
  font-size: 1em;
  font-weight: 400;
  color: #212529;
}

.DateInput_input__small {
  padding: 5px 7px 4px;
}

.DateInput_input__focused {
  border-bottom-color: #4164fb;
}

.CalendarDay__selected_span {
  background: #f6f9fc;
  color: #4d5b7c;
  border: 1px double #f6f9fc;
}

.CalendarDay__selected_span:hover {
  background: #4d5b7c;
  color: white;
  border: 1px double #4d5b7c;
}

.CalendarDay__selected {
  background: #4164fb;
  color: white;
  border: 1px double #4164fb;
}

.CalendarDay__selected:hover {
  background: #4d5b7c;
  color: white;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #4d5b7c;
  color: white;
}
